.Contact {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Contact .contact-item {
  align-items: center;
  display: flex;
  margin-bottom: 16px;
}
