.HomeScene.col {
  height: 100vh;
  width: calc(100vw - 128px - 15vw);
  min-width: 100vh;
  background: linear-gradient(45deg, #000000, #010d34);
  position: relative;
  flex-shrink: 0;
}

.HomeScene.row {
  height: 100vw;
  width: 100vw;
  min-height: 100vw;
  background: linear-gradient(45deg, #000000, #010d34);
  position: relative;
}
