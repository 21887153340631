@import url(https://rsms.me/inter/inter.css);
html {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

html {
  font-size: 87.5%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  box-sizing: border-box;
}

/* desktop */
@media (min-device-width: 1224px) {
  html {
    font-size: 100%;
  }
}

.App {
  display: flex;
  height: 100vh;
}

.App.only-portrait {
  flex-direction: column;
  height: auto;
  margin-top: 64px;
}

.App h2 {
  font-size: 3rem;
}

.App h3 {
  font-size: 2rem;
}

/* landscape */
@media (orientation: landscape) {
  .only-portrait {
    display: none;
  }
}

/* portrait */
@media (orientation: portrait) {
  .only-landscape {
    display: none;
  }
}

/* desktop */
@media (min-device-width: 1224px) {
  .only-mobile {
    display: none;
  }

  .App h2 {
    font-size: 4rem;
  }
}

/* mobile */
@media (max-device-width: 1224px) {
  .only-desktop {
    display: none;
  }
}

.Navbar {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  z-index: 999;
  background: #ffffff;
}

.Navbar.landscape-desktop {
  width: 128px;
  display: flex;
  flex-direction: column;
}

.Navbar.landscape-mobile {
  width: 64px;
  display: flex;
  flex-direction: column;
}

.Navbar.portrait-desktop,
.Navbar.portrait-mobile {
  height: 64px;
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.Navbar-linksContainer.landscape-desktop,
.Navbar-linksContainer.landscape-mobile {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Navbar-linksContainer.portrait-desktop,
.Navbar-linksContainer.portrait-mobile {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.NavbarLink-iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.NavbarLogo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.NavbarLogo-logo {
  width: 50%;
  height: 50%;
}

.NavbarLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.NavbarLink a {
  color: #000;
  color: initial;
  text-decoration: none;
}

.NavbarLink a.active svg {
  fill: inherit;
}

.HomeScene.col {
  height: 100vh;
  width: calc(100vw - 128px - 15vw);
  min-width: 100vh;
  background: -webkit-linear-gradient(45deg, #000000, #010d34);
  background: linear-gradient(45deg, #000000, #010d34);
  position: relative;
  flex-shrink: 0;
}

.HomeScene.row {
  height: 100vw;
  width: 100vw;
  min-height: 100vw;
  background: -webkit-linear-gradient(45deg, #000000, #010d34);
  background: linear-gradient(45deg, #000000, #010d34);
  position: relative;
}

.SceneImage {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  -webkit-animation-name: flutuation;
          animation-name: flutuation;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  position: absolute;
}

@-webkit-keyframes flutuation {
  0% {
    background-position-y: 0px;
  }

  100% {
    background-position-y: 10vh;
  }
}

@keyframes flutuation {
  0% {
    background-position-y: 0px;
  }

  100% {
    background-position-y: 10vh;
  }
}

.SceneContent {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  padding: 0px 10%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.SceneContent h2 {
  color: white;
  margin: 0;
  margin-bottom: 32px;
}

.SceneContent h2 span {
  display: block;
}

.SceneContent a {
  text-decoration: none;
}

.PrimaryButton {
  background: #0000ff;
  height: 48px;
  line-height: 48px;
  border-radius: 24px;
  border: none;
  padding: 0px 24px;
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Inter";
  letter-spacing: 2px;
  display: flex;
  cursor: pointer;

  -webkit-transition: all 300ms 0ms ease;

  transition: all 300ms 0ms ease;
}

.PrimaryButton-icon {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 16px;

  -webkit-transition: all 300ms 0ms ease;

  transition: all 300ms 0ms ease;
}

.PrimaryButton:hover {
  padding-right: 16px;
}

.PrimaryButton:hover .PrimaryButton-icon {
  margin-left: 24px;
}

.Home {
  display: flex;
  flex: 1 1;
}

.Home.landscape-desktop,
.Home.landscape-mobile {
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
}

.Home.portrait-desktop,
.Home.portrait-mobile {
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
}

.Portfolio {
  display: flex;
  flex: 1 1;
}

.Contact {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Contact .contact-item {
  align-items: center;
  display: flex;
  margin-bottom: 16px;
}

