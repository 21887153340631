.NavbarLogo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.NavbarLogo-logo {
  width: 50%;
  height: 50%;
}
