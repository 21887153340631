.NavbarLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.NavbarLink a {
  color: initial;
  text-decoration: none;
}

.NavbarLink a.active svg {
  fill: inherit;
}
