.App {
  display: flex;
  height: 100vh;
}

.App.only-portrait {
  flex-direction: column;
  height: auto;
  margin-top: 64px;
}

.App h2 {
  font-size: 3rem;
}

.App h3 {
  font-size: 2rem;
}

/* landscape */
@media (orientation: landscape) {
  .only-portrait {
    display: none;
  }
}

/* portrait */
@media (orientation: portrait) {
  .only-landscape {
    display: none;
  }
}

/* desktop */
@media (min-device-width: 1224px) {
  .only-mobile {
    display: none;
  }

  .App h2 {
    font-size: 4rem;
  }
}

/* mobile */
@media (max-device-width: 1224px) {
  .only-desktop {
    display: none;
  }
}
