.Navbar {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  z-index: 999;
  background: #ffffff;
}

.Navbar.landscape-desktop {
  width: 128px;
  display: flex;
  flex-direction: column;
}

.Navbar.landscape-mobile {
  width: 64px;
  display: flex;
  flex-direction: column;
}

.Navbar.portrait-desktop,
.Navbar.portrait-mobile {
  height: 64px;
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.Navbar-linksContainer.landscape-desktop,
.Navbar-linksContainer.landscape-mobile {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Navbar-linksContainer.portrait-desktop,
.Navbar-linksContainer.portrait-mobile {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.NavbarLink-iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
