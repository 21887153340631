.SceneImage {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  animation-name: flutuation;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  position: absolute;
}

@keyframes flutuation {
  0% {
    background-position-y: 0px;
  }

  100% {
    background-position-y: 10vh;
  }
}
