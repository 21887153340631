.SceneContent {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  padding: 0px 10%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.SceneContent h2 {
  color: white;
  margin: 0;
  margin-bottom: 32px;
}

.SceneContent h2 span {
  display: block;
}

.SceneContent a {
  text-decoration: none;
}
