.PrimaryButton {
  background: #0000ff;
  height: 48px;
  line-height: 48px;
  border-radius: 24px;
  border: none;
  padding: 0px 24px;
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Inter";
  letter-spacing: 2px;
  display: flex;
  cursor: pointer;

  transition: all 300ms 0ms ease;
}

.PrimaryButton-icon {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 16px;

  transition: all 300ms 0ms ease;
}

.PrimaryButton:hover {
  padding-right: 16px;
}

.PrimaryButton:hover .PrimaryButton-icon {
  margin-left: 24px;
}
