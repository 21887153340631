.Home {
  display: flex;
  flex: 1;
}

.Home.landscape-desktop,
.Home.landscape-mobile {
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
}

.Home.portrait-desktop,
.Home.portrait-mobile {
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
}
